// FIXME Change all snipcart names to sigaro or similar and merge the overrides here
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,500|Open+Sans:300,600);

.snipcart {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #313332
}

.snipcart * {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  color: inherit;
  background: transparent;
  display: block;
  line-height: 1;
  list-style: none;
  quotes: none;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.snipcart__font--secondary, .snipcart__font--subtitle {
  font-family: Montserrat, sans-serif
}

.snipcart__font--tiny {
  font-size: 12px
}

.snipcart__font--std {
  font-size: 14px;
  line-height: 20px
}

.snipcart__font--large {
  font-size: 16px
}

.snipcart__box--badge, .snipcart__font--subtitle, .snipcart__font--xlarge {
  font-size: 18px
}

.snipcart__font--slim {
  font-weight: 300
}

.snipcart__font--regular {
  font-weight: 400
}

.snipcart__font--bold {
  font-weight: 500;
  line-height: 20px
}

.snipcart__font--black, .snipcart__font--subtitle {
  font-weight: 600
}

.snipcart__font--subtitle {
  display: -webkit-box;
  display: flex
}

.snipcart__box {
  padding: 32px 16px;
  position: relative;
  margin-bottom: 10.66667px;
  background-color: #fff
}

@media (min-width: 1024px) {
  .snipcart__box {
    padding: 32px;
    margin-top: 0
  }
}

.snipcart__box--large {
  padding: 32px;
  margin-top: 0
}

.snipcart__box--no-margin {
  margin-bottom: 0
}

.snipcart__box--gray {
  background-color: #f7fafa
}

.snipcart__box--slim {
  padding: 32px 32px 16px 32px
}

@media (min-width: 1024px) {
  .snipcart__box--slim {
    padding: 16px 32px
  }
}

.snipcart__box--slim--large {
  padding: 16px 32px
}

.snipcart__box--header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center
}

@media (min-width: 1024px) {
  .snipcart__box--title {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center
  }
}

.snipcart__box--subtitle, .snipcart__box--title--large {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center
}

.snipcart__box--content {
  padding: 10.66667px 0
}

.snipcart__box--badge {
  height: 40px;
  width: 40px;
  border-radius: 999px;
  border: 3px solid #f0f5f6;
  background-color: #f7fafa;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-top: -48px;
  margin-bottom: 10.66667px
}

@media (min-width: 1024px) {
  .snipcart__box--badge {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -48px;
    margin-right: 8px
  }
}

.snipcart__box--badge--large {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -48px;
  margin-right: 8px
}

.snipcart__box--badge-highlight {
  background: -webkit-gradient(linear, left top, right top, from(#f4dfac), to(#d5c196));
  background: linear-gradient(90deg, #f4dfac, #d5c196);
  color: #2b2b2b
}

.snipcart-modal {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  background-color: #f0f5f6
}

@supports (-webkit-overflow-scrolling:touch) {
  .snipcart-modal input, .snipcart-modal select, .snipcart-modal textarea {
    font-size: 16px
  }
}

.snipcart-modal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  background-color: #f0f5f6
}

.snipcart-modal__overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: .5
}

.snipcart-modal__close {
  background-color: transparent;
  border: none
}

.snipcart-modal__close:focus {
  outline: 5px auto -webkit-focus-ring-color
}

.snipcart-modal__close:active {
  outline: none
}

.snipcart-modal__header {
  padding: 16px;
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 100%
}

.snipcart-modal__header--fixed {
  padding: 16px;
  min-height: 80px
}

.snipcart-modal__header-sumary {
  cursor: pointer
}

.snipcart-modal__header-summary-title {
  -webkit-box-pack: justify;
  justify-content: space-between
}

.snipcart-modal__close, .snipcart-modal__header-summary-title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer
}

.snipcart-modal__close {
  -webkit-box-pack: center;
  justify-content: center;
  font-family: Open Sans, sans-serif;
  color: #313332
}

.snipcart-modal__close-icon {
  margin-right: 10px
}

.snipcart-modal__close-title {
  display: none;
  line-height: 1.4
}

@media (min-width: 768px) {
  .snipcart-modal__close-title {
    display: inline;
    height: 20px
  }
}

.snipcart-modal__close-title--tablet-up {
  display: inline;
  height: 20px
}

.snipcart__actions--link {
  text-decoration: none;
  color: #d5c196;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  border: none;
  background-color: transparent;
  cursor: pointer
}

.snipcart__actions--link:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 5px
}

.snipcart__button--icon {
  border: 1px solid #f0f5f6;
  padding: 3.2px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer
}

.snipcart__button--icon img {
  width: 14px;
  height: 14px
}

.snipcart__button--icon:active, .snipcart__button--icon:focus {
  outline: none;
  border: 1px solid #d5c196
}

.snipcart__button--no-border {
  border: none;
  padding: 0;
  margin: 0
}

.snipcart__button--no-border:active, .snipcart__button--no-border:focus {
  border: none
}

.snipcart__button--with-icon {
  border: 1px solid #f0f5f6;
  padding: 8px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent
}

.snipcart__button--with-icon img {
  width: 14px;
  height: 14px
}

.snipcart__button--with-icon:active, .snipcart__button--with-icon:focus {
  outline: none;
  border: 1px solid #d5c196
}

.snipcart__button--with-icon--no-border, .snipcart__button--with-icon--no-border:active, .snipcart__button--with-icon--no-border:focus {
  border: none
}

.snipcart__notice--with-icon {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.snipcart__notice__icon {
  min-width: 20px;
  min-height: 20px
}

.snipcart__icon {
  width: 20px;
  height: 20px
}

.snipcart__icon--spin {
  -webkit-animation: spin .7s linear 0s infinite;
  animation: spin .7s linear 0s infinite
}

.snipcart__icon--angled {
  -webkit-transform: rotateY(0deg) rotate(45deg);
  transform: rotateY(0deg) rotate(45deg)
}

.snipcart__icon--left {
  margin-right: 10px
}

.snipcart__icon--small {
  width: 16px;
  height: 16px
}

.snipcart__icon--default {
  width: 125px;
  height: auto
}

.snipcart__icon--std {
  width: 20px;
  height: 20px
}

.snipcart__icon--medium {
  width: 30px;
  height: 30px
}

.snipcart__icon--large {
  width: 40px;
  height: 40px
}

.snipcart__icon--xlarge {
  width: 125px;
  height: 125px
}

.snipcart__icon--white path {
  fill: #fff
}

.snipcart__icon--darker path {
  fill: #313332
}

.snipcart__icon--dark path {
  fill: #989ba3
}

.snipcart__icon--red path {
  fill: #d93120
}

.snipcart__icon--brand {
  width: auto;
  height: 20px
}

.snipcart__icon--blue-light path {
  fill: #d5c196
}

.snipcart__icon--blue-dark path {
  fill: #5082e4
}

.snipcart-overlay--disabled {
  position: absolute;
  background-color: #fff;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .8
}

.snipcart-cart {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.snipcart-cart__layout {
  box-shadow: 10px 10px 15px 10px #d9dfe0
}

.snipcart-cart__featured-payment-methods-container {
  margin-top: 32px
}

.snipcart-cart__featured-payment-methods-title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 3.2px;
  margin-top: -1em;
  margin-bottom: 0;
  background-color: #f0f5f6
}

.snipcart-cart__content {
  margin: 0 auto;
  background-color: #f0f5f6
}

.snipcart-cart__edit-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between
}

.snipcart-cart__footer {
  padding: 0 16px
}

@media (min-width: 1024px) {
  .snipcart-cart__footer {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 10.66667px
  }
}

.snipcart-cart__footer--large {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 10.66667px
}

@media (min-width: 768px) and (max-width: 1024px) {
  .snipcart-cart__footer {
    display: block;
    margin-top: 0
  }
}

.snipcart__media-query--tablet .snipcart-cart__footer {
  display: block;
  margin-top: 0
}

@media (min-width: 768px) {
  .snipcart-cart__footer {
    padding: 0
  }
}

.snipcart-cart__footer--tablet-up {
  padding: 0
}

@media (min-width: 1024px) {
  .snipcart-cart__footer-col {
    //width: 33.33333%
    width: 40%;
  }
}

.snipcart-cart__footer-col--large {
  width: 33.33333%
}

@media (min-width: 768px) and (max-width: 1024px) {
  .snipcart-cart__footer-col {
    width: 100%
  }
}

.snipcart__media-query--tablet .snipcart-cart__footer-col {
  width: 100%
}

@media (min-width: 768px) and (max-width: 1024px) {
  .snipcart-cart__footer-discount-box {
    padding-bottom: 20px
  }
}

.snipcart__media-query--tablet .snipcart-cart__footer-discount-box {
  padding-bottom: 20px
}

.snipcart-cart__discount-box {
  margin-bottom: 40px
}

.snipcart-cart__secondary-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1;
  flex-shrink: 0;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  background-color: #f8fafa;
  height: 100%;
  max-height: 64px;
  min-height: 64px;
  padding-left: 24px;
  padding-right: 24px
}

.snipcart-cart__secondary-header-title {
  -webkit-box-flex: 1;
  flex: 1
}

@media (min-width: 1024px) {
  .snipcart-cart__secondary-header {
    min-height: 96px;
    padding-left: 64px;
    padding-right: 32px
  }
}

.snipcart-cart__secondary-header--large {
  min-height: 96px;
  padding-left: 64px;
  padding-right: 32px
}

.snipcart-cart__checkout-button {
  margin-top: 16px
}

.snipcart-cart--edit .snipcart-cart__footer-col {
  width: 100%
}

.snipcart-cart--edit .snipcart-cart__discount-box {
  margin-bottom: 40px
}

.snipcart-cart--edit .snipcart-cart__content {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  -webkit-box-flex: 1;
  flex: 1;
  margin: 0
}

.snipcart-cart--edit .snipcart-cart__footer {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  padding: 32px;
  display: block
}

@media (min-width: 1024px) {
  .snipcart-cart--edit .snipcart-cart__footer {
    padding: 16px 64px
  }
}

.snipcart-cart--edit .snipcart-cart__footer--large {
  padding: 16px 64px
}

.snipcart-cart--edit {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  max-width: 540px;
  background-color: #fff;
  height: 100%
}

.snipcart-cart-header {
  background-color: #f0f5f6;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 400;
  top: 0;
  width: 100%;
  margin-bottom: 16px
}

@media (min-width: 768px) {
  .snipcart-cart-header {
    width: 80%;
    margin: 0 auto 16px auto
  }
}

.snipcart-cart-header--tablet-up {
  width: 80%;
  margin: 0 auto 16px auto
}

@media (min-width: 1024px) {
  .snipcart-cart-header {
    padding-right: 0;
    padding-left: 0;
    z-index: auto;
    height: auto;
    position: relative;
    padding: 32px 16px
  }
}

.snipcart-cart-header--large {
  padding-right: 0;
  padding-left: 0;
  z-index: auto;
  height: auto;
  position: relative;
  padding: 32px 16px
}

.snipcart-cart-header__close-button {
  -webkit-box-flex: 1;
  flex: 1;
  -webkit-box-pack: start;
  justify-content: flex-start
}

.snipcart-cart-header__title {
  -webkit-box-flex: 1;
  flex: 1;
  text-align: center
}

.snipcart-cart-header__options {
  -webkit-box-flex: 1;
  flex: 1
}

.snipcart-cart-header__option, .snipcart-cart-header__options {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end
}

.snipcart-cart-header__option {
  text-decoration: none;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-cart-header__option:not(:last-child) {
  margin-right: 16px
}

.snipcart-cart-header__count, .snipcart-cart-header__sign-in, .snipcart-cart-header__sign-out {
  cursor: pointer
}

.snipcart-cart-header__customer-account {
  margin-right: 24px;
  cursor: pointer
}

.snipcart-cart-header__icon {
  margin-right: 5px
}

.snipcart-cart-header--fixed {
  position: fixed
}

.snipcart-empty-cart {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 80vh;
  color: #989ba3
}

.snipcart-empty-cart, .snipcart-empty-cart__title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-empty-cart__title {
  height: 70%
}

.snipcart-custom-field-wrapper__label {
  margin-bottom: 8px
}

.snipcart-custom-field-wrapper__label .required__asterisk {
  color: #d93120;
  display: inline-block
}

@media (min-width: 1024px) {
  .snipcart-dropdown-custom-field--standard {
    width: 33.33333%
  }
  .snipcart-dropdown-custom-field--medium {
    width: 66.66667%
  }
  .snipcart-dropdown-custom-field--large {
    width: 100%
  }
}

.snipcart-dropdown-custom-field--large--standard {
  width: 33.33333%
}

.snipcart-dropdown-custom-field--large--medium {
  width: 66.66667%
}

.snipcart-dropdown-custom-field--large--large {
  width: 100%
}

@media (min-width: 768px) and (max-width: 1024px) {
  .snipcart-dropdown-custom-field--large, .snipcart-dropdown-custom-field--medium, .snipcart-dropdown-custom-field--standard {
    width: 100%
  }
}

.snipcart__media-query--tablet .snipcart-dropdown-custom-field--large, .snipcart__media-query--tablet .snipcart-dropdown-custom-field--medium, .snipcart__media-query--tablet .snipcart-dropdown-custom-field--standard {
  width: 100%
}

@-webkit-keyframes slideIn-data-v-68af19e0 {
  0% {
    -webkit-transform: translateX(110%);
    transform: translateX(110%)
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes slideIn-data-v-68af19e0 {
  0% {
    -webkit-transform: translateX(110%);
    transform: translateX(110%)
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes spin-data-v-68af19e0 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes spin-data-v-68af19e0 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-webkit-keyframes slideDown-data-v-68af19e0 {
  0% {
    height: 0
  }
  to {
    height: 100vh
  }
}

@keyframes slideDown-data-v-68af19e0 {
  0% {
    height: 0
  }
  to {
    height: 100vh
  }
}

@-webkit-keyframes fadeIn-data-v-68af19e0 {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeIn-data-v-68af19e0 {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.snipcart-input[data-v-68af19e0] {
  height: unset
}

.snipcart-textarea-custom-field[data-v-68af19e0] {
  height: 80px;
  width: 100%;
  background-color: #fdfefe;
  border: 1px solid #f0f5f6;
  padding: 8px
}

.snipcart-text-custom-field {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #313332;
  background-color: #fdfefe;
  line-height: normal;
  height: 52px;
  border: 1px solid #f0f5f6
}

.snipcart-item-custom-fields {
  width: 100%
}

@media (min-width: 1024px) {
  .snipcart-item-custom-fields {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap
  }
}

.snipcart-item-custom-fields--large {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap
}

@media (min-width: 768px) and (max-width: 1024px) {
  .snipcart-item-custom-fields {
    display: block
  }
}

.snipcart__media-query--tablet .snipcart-item-custom-fields {
  display: block
}

.snipcart-item-custom-fields__field {
  margin-bottom: 10px
}

@media (min-width: 1024px) {
  .snipcart-item-custom-fields__field {
    padding-right: 20px
  }
}

.snipcart-item-custom-fields__field--large {
  padding-right: 20px
}

@media (min-width: 768px) and (max-width: 1024px) {
  .snipcart-item-custom-fields__field {
    padding-right: 0
  }
}

.snipcart__media-query--tablet .snipcart-item-custom-fields__field {
  padding-right: 0
}

.snipcart-cart--edit .snipcart-item-custom-fields {
  display: block
}

.snipcart-cart--edit .snipcart-item-custom-fields__field {
  padding-right: 0;
  width: 100%
}

.snipcart-item-description {
  max-width: 500px;
  line-height: 20px
}

.snipcart-item-line {
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(231, 235, 237, .6)
}

.snipcart-item-line__container {
  padding: 32px;
  display: -webkit-box;
  display: flex;
  margin: 0 16px 24px 16px
}

@media (min-width: 768px) {
  .snipcart-item-line__container {
    margin: 0 0 24px 0
  }
}

.snipcart-item-line__container--tablet-up {
  margin: 0 0 24px 0
}

.snipcart-item-line__header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px
}

@media (min-width: 1024px) {
  .snipcart-item-line__header {
    margin-bottom: 0
  }
}

.snipcart-item-line__header--large {
  margin-bottom: 0
}

.snipcart-item-line__header__title {
  padding: 0;
  margin: 0
}

.snipcart-item-line__quantity {
  min-width: 25%
}

@media (min-width: 1024px) {
  .snipcart-item-line__quantity {
    min-width: 240px
  }
}

.snipcart-item-line__quantity--large {
  min-width: 240px
}

@media (min-width: 768px) and (max-width: 1024px) {
  .snipcart-item-line__quantity {
    width: auto
  }
}

.snipcart__media-query--tablet .snipcart-item-line__quantity {
  width: auto
}

.snipcart-item-line__body {
  width: 100%
}

@media (min-width: 768px) and (max-width: 1024px) {
  .snipcart-item-line__body {
    display: block
  }
}

.snipcart__media-query--tablet .snipcart-item-line__body {
  display: block
}

.snipcart-item-line__product {
  width: 100%
}

.snipcart-item-line__variants {
  padding-top: 16px
}

@media (min-width: 1024px) {
  .snipcart-item-line__variants {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 8px
  }
}

.snipcart-item-line__variants--large {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 8px
}

.snipcart-item-line__info {
  width: 100%;
  padding: 8px 0
}

.snipcart-item-line__image {
  width: auto;
  max-height: 100%
}

@media (min-width: 1024px) {
  .snipcart-item-line__image {
    width: 120px
  }
}

.snipcart-item-line__image--large {
  width: 120px
}

.snipcart-item-line__media {
  margin: 0;
  padding: 0;
  min-width: 120px;
  margin-right: 32px
}

.snipcart-item-line__media--small {
  min-width: auto;
  height: 32px;
  margin: 0;
  width: auto
}

.snipcart-item-line--cart-edit .snipcart-item-line__edit {
  max-width: 40px;
  width: auto
}

.snipcart-item-line--cart-edit .snipcart-item-line__variants {
  display: block
}

.snipcart-item-line--cart-edit .snipcart-item-line__title {
  -webkit-box-flex: 1;
  flex: 1;
  text-align: left;
  padding-left: 8px
}

.snipcart-item-line--cart-edit .snipcart-item-line__product {
  width: 100%
}

.snipcart-item-line--cart-edit .snipcart-item-line__image {
  max-width: 40px;
  width: auto
}

.snipcart-item-line--cart-edit {
  box-shadow: none;
  padding: 32px 0;
  border-bottom: 1px solid #f0f5f6;
  margin: 0 32px
}

@media (min-width: 1024px) {
  .snipcart-item-line--cart-edit {
    margin: 0 64px
  }
}

.snipcart-item-line--cart-edit--large {
  margin: 0 64px
}

.snipcart-item-line:last-child {
  margin-bottom: 0
}

.snipcart-item-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 32px
}

.snipcart-cart--edit .snipcart-item-list {
  background-color: #fff;
  margin-bottom: 0
}

.snipcart-item-quantity {
  color: #313332;
  margin-top: -2px
}

.snipcart-item-quantity__label {
  margin-bottom: 8px;
  display: inline-block
}

.snipcart-item-quantity__button {
  max-height: 18px
}

.snipcart-item-quantity__button:focus path {
  fill: #d5c196
}

.snipcart-item-quantity__quantity {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #313332;
  line-height: normal;
  height: 52px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #fdfefe;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border: 1px solid #f0f5f6;
  width: 50%
}

.snipcart-item-quantity__quantity--disabled {
  -webkit-box-pack: center;
  justify-content: center
}

.snipcart-item-quantity__quantity-price-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-checkout__content {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto
}

.snipcart-checkout__step-cols {
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10.66667px 0
}

.snipcart-checkout__step-col, .snipcart-checkout__step-cols {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start
}

.snipcart-checkout__step-title {
  display: none
}

.snipcart-dashboard__content {
  margin: 0 16px 24px 16px;
  max-width: 1440px;
  display: -webkit-box;
  display: flex
}

@media (min-width: 768px) {
  .snipcart-dashboard__content {
    width: 80%;
    margin-left: auto;
    margin-right: auto
  }
}

.snipcart-dashboard__content--tablet-up {
  width: 80%;
  margin-left: auto;
  margin-right: auto
}

.snipcart-dashboard__load-more-button {
  max-width: 560px
}

.snipcart-dashboard__notice {
  color: #989ba3;
  margin-top: 15%
}

.snipcart-dashboard__orders {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 24px
}

.snipcart-dashboard__order {
  padding: 24px;
  margin-bottom: 32px;
  width: 100%;
  max-width: 560px
}

@media (min-width: 768px) {
  .snipcart-dashboard__order {
    padding-left: 32px;
    padding-right: 32px
  }
}

.snipcart-dashboard__order--tablet-up {
  padding-left: 32px;
  padding-right: 32px
}

.snipcart-dashboard__order:last-child {
  margin-bottom: 0
}

.snipcart-dashboard__order-link {
  text-align: right;
  font-weight: 700
}

.snipcart-dashboard__order-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap
}

.snipcart-dashboard__order-header-title {
  margin-bottom: 8px
}

.snipcart-dashboard__order-status {
  font-weight: 700
}

.snipcart-dashboard__order-item {
  display: -webkit-box;
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px solid #e7ebed;
  margin-bottom: 24px
}

.snipcart-dashboard__order-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none
}

.snipcart-dashboard__order-item-details {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center
}

.snipcart-dashboard__order-item-details--row {
  display: -webkit-box;
  display: flex
}

.snipcart-dashboard__order-item-image {
  width: 80px;
  height: 80px;
  margin-right: 24px;
  -o-object-fit: cover;
  object-fit: cover
}

.snipcart-dashboard__order-item-name {
  margin-bottom: 16px
}

.snipcart-dashboard__order-item-price, .snipcart-dashboard__order-item-quantity {
  margin-right: 24px
}

.snipcart-dashboard__invoice-number, .snipcart-dashboard__ordered-on {
  margin-bottom: 24px
}

.snipcart-guest-checkout__wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px
}

.snipcart-guest-checkout__or {
  position: relative;
  z-index: 99;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  background: #fdfefe;
  margin-left: auto;
  margin-right: auto
}

.snipcart-guest-checkout__seperator {
  margin-top: -5px;
  position: relative;
  height: 1px;
  width: 100%;
  background-color: #d9dfe0
}

.snipcart-guest-checkout__link {
  text-align: center;
  color: #d5c196
}

.snipcart-register__title {
  margin-bottom: 16px
}

.snipcart-register__register {
  margin-bottom: 32px
}

.snipcart-register__box {
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px
}

.snipcart-register .snipcart-register__form {
  box-shadow: none
}

.snipcart-register__register-link {
  display: inline;
  color: #d5c196;
  cursor: pointer
}

.snipcart-register__forgot-password-link {
  text-align: right;
  margin-top: 8px;
  color: #d5c196
}

.snipcart-register__submit-button {
  margin-top: 32px
}

.snipcart-signin__title {
  margin-bottom: 16px
}

.snipcart-signin__register {
  margin-bottom: 32px
}

.snipcart-signin__box {
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px
}

.snipcart-signin .snipcart-signin__form {
  box-shadow: none
}

.snipcart-signin__register-link {
  display: inline;
  color: #d5c196;
  cursor: pointer
}

.snipcart-signin__forgot-password-link {
  text-align: right;
  margin-top: 8px;
  color: #d5c196
}

.snipcart-signin__submit-button {
  margin-top: 32px
}

.snipcart-order__box {
  margin-bottom: 1px
}

.snipcart-order__details-title, .snipcart-order__title {
  margin-bottom: 24px
}

.snipcart-order__card {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-order__step-title {
  display: block;
  margin-bottom: 16px
}

.snipcart-order__footer {
  margin-bottom: 32px
}

.snipcart-order__details {
  margin-bottom: 32px;
  margin: 0 16px 24px 16px
}

@media (min-width: 1024px) {
  .snipcart-order__details {
    width: 100%;
    max-width: 566px;
    margin: 0 auto
  }
}

.snipcart-order__details--large {
  width: 100%;
  max-width: 566px;
  margin: 0 auto
}

.snipcart-order__invoice-number {
  margin-top: 10.66667px;
  display: -webkit-box;
  display: flex
}

.snipcart-order__invoice-number--highlight {
  color: #d5c196
}

.snipcart-cart-button {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 32px
}

.snipcart-cart-button__icon {
  min-width: 16px;
  -webkit-box-flex: 1;
  flex: 1
}

.snipcart-cart-button__icon--right {
  margin-left: 8px
}

.snipcart-cart-button__icon--left {
  margin-right: 8px
}

.snipcart-cart-button--fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.snipcart-cart-button--highlight {
  background-image: -webkit-gradient(linear, left top, right top, from(#f4dfac), to(#d5c196));
  background-image: linear-gradient(90deg, #f4dfac, #d5c196)
}

.snipcart-cart-button--highlight .snipcart__icon path {
  fill: #fff
}

.snipcart-cart-button--highlight:focus {
  box-shadow: 2px 2px 6px rgba(80, 130, 228, .3)
}

.snipcart-cart-button--disabled {
  opacity: .7
}

.snipcart-cart-button--disabled, .snipcart-cart-button--loading {
  cursor: default;
  background-image: -webkit-gradient(linear, left top, right top, from(#72a0f0), to(#37cbfe));
  background-image: linear-gradient(90deg, #72a0f0, #37cbfe)
}

.snipcart-cart-button--secondary {
  color: #989ba3;
  background-color: #e7ebed
}

.snipcart-cart-button--secondary .snipcart__icon path {
  fill: #989ba3
}

.snipcart-cart-button--secondary:focus {
  box-shadow: 2px 2px 6px rgba(152, 155, 163, .3)
}

.snipcart-cart-button:focus {
  outline: none;
  border: none
}

.snipcart-billing-completed__step-title {
  display: none
}

.snipcart-billing-completed__header {
  margin-bottom: 24px
}

.snipcart-billing-completed__slide-transition--enter-active {
  -webkit-animation: fadeIn .3s;
  animation: fadeIn .3s
}

.snipcart-billing-completed__slide-transition--enter-leave {
  animation: fadeIn .3s reverse
}

.snipcart-billing-completed--titled .snipcart-billing-completed__step-title {
  display: block;
  margin-bottom: 16px
}

.snipcart-billing-completed--titled .snipcart-billing-completed__step-icon {
  display: none
}

.snipcart-payment-card-form iframe {
  border: none;
  width: 100%
}

.snipcart-payment-card-form__error {
  margin-top: 24px
}

.snipcart-payment-card-form__container {
  position: relative
}

.snipcart-payment-card-authentication__container iframe {
  width: 100%;
  height: 0
}

.snipcart-payment-card-authentication__container--visible iframe {
  height: 400px
}

.snipcart-payment__header {
  margin-bottom: 16px
}

.snipcart-payment__no-payment {
  margin-top: 12px;
  margin-bottom: 16px
}

.snipcart-payment__form-container--loading, .snipcart-payment__loading {
  min-height: 160px
}

.snipcart-payment__form-container--loading * {
  visibility: hidden
}

.snipcart-payment-completed__title {
  margin-bottom: 24px
}

.snipcart-payment-completed__step-title {
  margin-bottom: 16px
}

.snipcart-featured-payment-methods {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 24px;
  flex-wrap: wrap;
  border-top: 1px solid #d9dfe0;
  margin-top: 40px;
  margin-bottom: 80px;
  position: relative
}

.snipcart-featured-payment-methods__title-icon {
  margin-right: 8px
}

.snipcart-featured-payment-methods__list {
  display: -webkit-box;
  display: flex
}

.snipcart-featured-payment-methods__link {
  color: inherit;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-featured-payment-methods__link:hover {
  color: inherit
}

.snipcart-featured-payment-methods__title {
  background-color: #f0f5f6;
  position: absolute;
  top: -10px;
  padding-left: 16px;
  padding-right: 16px
}

.snipcart-featured-payment-methods__list-item {
  padding: 8px 16px;
  text-align: center
}

.snipcart-featured-payment-methods--no-background {
  border: none;
  margin-top: 0;
  margin-top: 32px
}

.snipcart-featured-payment-methods--no-background .snipcart-featured-payment-methods {
  border: none
}

.snipcart-featured-payment-methods--no-background .snipcart-featured-payment-methods__title {
  background-color: transparent
}

.snipcart-payment-methods-list__container {
  position: relative
}

.snipcart-payment-methods-list-item {
  margin-top: 10.66667px
}

.snipcart-payment-methods-list-item__button {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid #f0f5f6;
  padding: 16px;
  width: 100%;
  height: 64px;
  cursor: pointer
}

.snipcart-payment-methods-list-item__label {
  text-align: left;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-payment-methods-list-item__icon {
  height: 32px;
  width: auto;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle
}

.snipcart-shipping-completed__step-title {
  display: none
}

.snipcart-shipping-completed__header {
  margin-bottom: 24px
}

.snipcart-shipping-completed--titled .snipcart-shipping-completed__step-title {
  display: block;
  margin-bottom: 16px
}

.snipcart-shipping-completed--titled .snipcart-shipping-completed__step-icon {
  display: none
}

.snipcart-shipping-address__header--readonly {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between
}

.snipcart-shipping-address__content {
  margin-bottom: 10.66667px
}

.snipcart-shipping-address__subtitle {
  margin-top: 16px;
  margin-bottom: 8px
}

.snipcart-shipping-address__address-field {
  margin-bottom: 24px
}

.snipcart-address-list-item:focus-within,
.snipcart-shipping-rates-list-item:focus-within {
  border: 1px solid #d5c196
}

.snipcart-address-list-item--right,
.snipcart-shipping-rates-list-item--right {
  white-space: nowrap;
  text-align: right
}

.snipcart-address-list-item--highlight,
.snipcart-shipping-rates-list-item--highlight {
  border: 1px solid #d5c196
}

.snipcart-cart-summary {
  background-color: #f8fafa
}

.snipcart-cart-summary__content {
  background-color: #fff;
  padding: 32px
}

.snipcart-cart-summary__small {
  background-color: hsla(0, 0%, 100%, .8);
  z-index: 2;
  position: absolute;
  left: 0;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  margin-top: 32px
}

.snipcart-cart-summary__small-transition-enter-active {
  -webkit-animation: slideDown .3s;
  animation: slideDown .3s
}

.snipcart-cart-summary__small-transition-leave-active {
  animation: slideDown .1s reverse
}

.snipcart-cart-summary__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(253, 254, 254, .7)
}

.snipcart-cart-summary__overlay-transition-enter-active {
  -webkit-animation: fadeIn .3s;
  animation: fadeIn .3s
}

.snipcart-cart-summary__overlay-transition-leave-active {
  animation: fadeIn .1s reverse
}

.snipcart-cart-summary__items {
  list-style-type: none;
  margin: 0
}

.snipcart-cart-summary__separator {
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-right: -32px;
  margin-top: 32px;
  margin-bottom: 32px;
  border: 0;
  height: 1px;
  background: #f0f5f6
}

.snipcart-cart-summary__actions {
  margin-bottom: 24px
}

.snipcart-cart-summary--small {
  background-color: hsla(0, 0%, 100%, .5);
  z-index: 2;
  position: absolute;
  left: 0;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  margin-top: 16px
}

.snipcart-cart-summary--edit.snipcart-modal__container {
  box-shadow: 0 8px 24px rgba(231, 235, 237, .6)
}

@media (min-width: 768px) {
  .snipcart-cart-summary--edit.snipcart-modal__container {
    width: 45%;
    max-width: 540px;
    margin-left: auto;
    right: 0
  }
}

.snipcart-cart-summary--edit.snipcart-modal__container--tablet-up {
  width: 45%;
  max-width: 540px;
  margin-left: auto;
  right: 0
}

.snipcart-cart-summary-fees {
  color: #313332
}

.snipcart-cart-summary-fees__item {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 3.2px 0
}

.snipcart-cart-summary-fees__item:not(:last-child) {
  margin-bottom: 8px
}

.snipcart-cart-summary-fees__title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0
}

.snipcart-cart-summary-fees__amount {
  text-align: right
}

.snipcart-cart-summary-fees__notice {
  margin-top: 24px
}

.snipcart-cart-summary-fees__discounts-icon {
  position: relative;
  margin-left: 8px
}

.snipcart-cart-summary-fees__discount-container {
  display: -webkit-box;
  display: flex
}

.snipcart-cart-summary-fees__discount-container:not(:last-of-type) {
  margin-bottom: 8px
}

.snipcart-cart-summary-fees__discount-name {
  white-space: nowrap
}

.snipcart-cart-summary-fees__discount-amount-saved {
  margin-left: auto;
  padding-left: 16px
}

.snipcart-cart-summary-fees--reverse {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse
}

.snipcart-cart-summary-item {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 60% 30px auto;
  color: #313332;
  padding: 3.2px 0
}

.snipcart-cart-summary-item__quantity {
  white-space: nowrap;
  text-align: center
}

.snipcart-cart-summary-item__price {
  text-align: right
}

.snipcart-cart-summary-item__custom-fields {
  list-style: none;
  margin: 0;
  padding: 0
}

.snipcart-cart-summary-item:not(:last-child) {
  margin-bottom: 8px
}

.snipcart-cart-summary-expanded-item {
  margin: 0;
  padding: 0;
  color: #313332;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  padding: 3.6px 0
}

.snipcart-cart-summary-expanded-item__quantity {
  white-space: nowrap
}

@media (min-width: 1024px) {
  .snipcart-cart-summary-expanded-item__price {
    text-align: right
  }
}

.snipcart-cart-summary-expanded-item__price--large {
  text-align: right
}

.snipcart-cart-summary-expanded-item__custom-fields {
  padding: 8px 0 8px 8px
}

@media (min-width: 1024px) {
  .snipcart-cart-summary-expanded-item__custom-fields {
    padding: 8px 0
  }
}

.snipcart-cart-summary-expanded-item__custom-fields--large {
  padding: 8px 0
}

.snipcart-cart-summary-expanded-item--secondary {
  padding: 0 0 8px 8px
}

.snipcart-cart-summary-expanded-item--secondary:last-child {
  padding-bottom: 0
}

@media (min-width: 1024px) {
  .snipcart-cart-summary-expanded-item {
    display: grid;
    grid-template-columns: 70% 50px auto
  }
}

.snipcart-cart-summary-expanded-item--large {
  display: grid;
  grid-template-columns: 70% 50px auto
}

.snipcart-tool-tip {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 12px 24px #eef6f9;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  top: calc(100% + 8px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 8px
}

.snipcart-tool-tip__container {
  padding: 16px;
  position: relative
}

.snipcart-tool-tip--top:after {
  content: "";
  position: absolute;
  width: 32px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 8px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 8px solid #fff;
  top: -8px
}

.snipcart-checkout-step {
  padding-bottom: 16px
}

.snipcart-checkout-step__cols {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between
}

@media (min-width: 1024px) {
  .snipcart-footer,
  .snipcart-form {
    max-width: 640px;
  }
  .snipcart-checkout-step__cols {
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
    flex-direction: unset
  }
}

.snipcart-checkout-step__cols--large {
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
  flex-direction: unset
}

.snipcart-checkout-step__col {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%
}

@media (min-width: 1024px) {
  .snipcart-checkout-step__col {
    width: 40%
  }
}

.snipcart-checkout-step__col--large {
  width: 40%
}

.snipcart-checkout-step__col:not(:last-child) {
  margin-bottom: 16px
}

@media (min-width: 1024px) {
  .snipcart-checkout-step__col:not(:last-child) {
    margin-bottom: 0
  }
}

.snipcart-checkout-step__col:not(:last-child) \--large {
  margin-bottom: 0
}

.snipcart-checkout-step__icon {
  width: 20px;
  margin-right: 5px;
  margin-top: 2px
}

//.snipcart-discount-box {
//  background-color: #f0f5f6
//}

@media (min-width: 1024px) {
  .snipcart-discount-box {
    width: 100%;
    margin: 0
  }
}

.snipcart-discount-box--large {
  width: 100%;
  margin: 0
}

.snipcart-discount-box__success-icon {
  margin-left: 16px
}

.snipcart-discount-box__button {
  cursor: pointer;
  box-sizing: border-box;
  padding: 16px 0;
  text-align: center;
  border: 1px solid #d9dfe0;
  width: 100%;
  background-color: transparent
}

.snipcart-discount-box__button:focus {
  border: 1px solid #d5c196
}

.snipcart-discount-box__form {
  -webkit-box-pack: justify;
  justify-content: space-between;
  border: 1px solid #d5c196;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  padding-left: 16px;
  padding-right: 16px
}

.snipcart-discount-box__form, .snipcart-discount-box__form--success {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-discount-box__form--success {
  color: #313332;
  background-color: #fdfefe;
  line-height: normal;
  height: 52px;
  border: 1px solid #f0f5f6;
  border: 1px solid #62e253;
  -webkit-box-pack: center;
  justify-content: center
}

.snipcart-discount-box__form-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-discount-box__submit {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #d5c196
}

.snipcart-discount-box__submit:focus {
  outline: none
}

.snipcart-discount-box__cancel {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #313332;
  margin-left: 8px
}

.snipcart-cart--edit .snipcart-discount-box, .snipcart-discount-box--white {
  background-color: #fff
}

.snipcart-address-autocomplete__hidden-textbox {
  opacity: 0;
  position: absolute;
  border: 0;
  width: 1px;
  height: 1px
}

.snipcart-address-autocomplete__empty {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100%
}

@media (max-width: 768px) {
  .snipcart-address-autocomplete__header {
    background-color: #fff;
    padding: 3.2px 8px
  }
  .snipcart-address-autocomplete .snipcart-typeahead__suggestions {
    border: none
  }
  .snipcart-address-autocomplete .snipcart-typeahead {
    position: fixed;
    top: 0;
    left: 0
  }
  .snipcart-address-autocomplete .snipcart-typeahead__input {
    padding: 0 16px 16px 16px;
    background-color: #fff
  }
}

@media (max-width: 768px) and (min-width: 1024px) {
  .snipcart-address-autocomplete .snipcart-typeahead__input {
    padding: 0
  }
}

@media (max-width: 768px) {
  .snipcart-address-autocomplete .snipcart-typeahead__input--large {
    padding: 0
  }
  .snipcart-address-autocomplete .snipcart-typeahead__suggestions {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-bottom: 50vh
  }
  .snipcart-address-autocomplete .snipcart-typeahead__suggestion--highlight {
    display: block
  }
  .snipcart-address-autocomplete .snipcart-typeahead__suggestion--loading {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center
  }
  .snipcart-address-autocomplete .snipcart-typeahead--active .snipcart-address-autocomplete .snipcart-typeahead .snipcart-typeahead___content, .snipcart-address-autocomplete .snipcart-typeahead__suggestion--loading {
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column
  }
  .snipcart-address-autocomplete .snipcart-typeahead--active .snipcart-address-autocomplete .snipcart-typeahead .snipcart-typeahead__suggestions {
    position: relative;
    max-height: 100vh;
    height: 100vh;
    margin: 0
  }
}

.snipcart-address-autocomplete--mobile__header {
  background-color: #fff;
  padding: 3.2px 8px
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead__suggestions {
  border: none
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead {
  position: fixed;
  top: 0;
  left: 0
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead__input {
  padding: 0 16px 16px 16px;
  background-color: #fff
}

@media (min-width: 1024px) {
  .snipcart-address-autocomplete--mobile .snipcart-typeahead__input {
    padding: 0
  }
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead__input--large {
  padding: 0
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead__suggestions {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-bottom: 50vh
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead__suggestion--highlight {
  display: block
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead__suggestion--loading {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead--active .snipcart-address-autocomplete--mobile .snipcart-typeahead .snipcart-typeahead___content, .snipcart-address-autocomplete--mobile .snipcart-typeahead__suggestion--loading {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column
}

.snipcart-address-autocomplete--mobile .snipcart-typeahead--active .snipcart-address-autocomplete--mobile .snipcart-typeahead .snipcart-typeahead__suggestions {
  position: relative;
  max-height: 100vh;
  height: 100vh;
  margin: 0
}

.snipcart-address-autocomplete--address {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #313332;
  background-color: #fdfefe;
  line-height: normal;
  height: 52px;
  border: 1px solid #f0f5f6
}

.snipcart-address-autocomplete--invalid, .snipcart-address-autocomplete--invalid:focus {
  border: 1px solid #d93120
}

.snipcart-checkbox {
  position: absolute;
  opacity: 0;
  -webkit-transition: box-shadow .2s ease-in-out;
  transition: box-shadow .2s ease-in-out
}

.snipcart-checkbox + label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0
}

.snipcart-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border: 1px solid #e0eaec;
  border-radius: 3px;
  background-color: #fdfefe
}

.snipcart-checkbox:focus + label:before, .snipcart-checkbox:hover + label:before {
  border: 1px solid #d5c196
}

.snipcart-checkbox:checked + label:before {
  border: 1px solid #d5c196;
  background-color: #d5c196;
  box-shadow: 0 2px 3px rgba(80, 130, 228, .3)
}

.snipcart-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #fff;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.snipcart-error-message {
  padding-top: 8px
}

.snipcart-error-message__action {
  color: #d5c196
}

.snipcart-error-message--global-form {
  padding-top: 0;
  padding-bottom: 8px
}

//.snipcart-form {
//  background-color: #fff;
//  box-shadow: 0 8px 24px rgba(231, 235, 237, .6)
//}

.snipcart-form__field {
  padding: 8px 0
}

@media (min-width: 1024px) {
  .snipcart-form__field {
    position: relative
  }
}

.snipcart-form__field--large {
  position: relative
}

.snipcart-form__field-checkbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 3.2px
}

.snipcart-form__hidden-autofill:-webkit-autofill {
  background-color: #e8f0fe !important
}

.snipcart-form__birthdate {
  display: flex;
}
.snipcart-form__birthdate select,
.snipcart-form__select {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #313332;
  line-height: normal;
  height: 52px;
  //border: 1px solid #f0f5f6;
  border: 1px solid #cccccc;
  width: 100%;
  padding: 16px;
  //background: url(/themes/v3.0.15/default/assets/icons/select-cursor.svg) no-repeat right 22px center;
  background-color: #fdfefe;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
}

.snipcart-form__birthdate select:not(:last-child) {
  margin-right: 10px;
}

.snipcart-form__birthdate select:-webkit-autofill,
.snipcart-form__select:-webkit-autofill {
  background-color: #e8f0fe !important
}

.snipcart-form__birthdate select:focus,
.snipcart-form__select:focus {
  outline: none;
  border: 1px solid #d5c196
}

.snipcart-form__set {
  border: none;
  padding: 0
}

.snipcart-form__footer {
  margin-top: 8px
}

.snipcart-form__row {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between
}

.snipcart-form__cell--large {
  width: 100%;
  margin-right: 32px
}

.snipcart-form__cell--tidy {
  width: 30%
}

.snipcart-form__cell--1st-half {
  width: 50%;
  margin-right: 10.66667px
}

.snipcart-form__cell--2nd-half {
  width: 50%
}

.snipcart-form__separator {
  width: 100%;
  border: 0;
  height: 1px;
  background: #f0f5f6;
  margin-top: 16px;
  margin-bottom: 16px
}

.snipcart-form__label {
  color: #313332;
  margin-bottom: 8px;
  display: inline-block;
  white-space: nowrap
}

.snipcart-form__label--radio {
  margin-bottom: 0;
  white-space: nowrap
}

.snipcart-form__label--checkbox {
  margin-bottom: 0
}

@media (min-width: 1024px) {
  .snipcart-form__label--mobile-only {
    display: none
  }
}

.snipcart-form__label--mobile-only--large {
  display: none
}

.snipcart-input {
  display: -webkit-box;
  -webkit-box-align: center;
  color: #313332;
  background-color: #fdfefe;
  line-height: normal;
  height: 52px;
  border: 1px solid #f0f5f6;
  position: relative;
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center
}

.snipcart-input:focus-within {
  outline: none;
  border: 1px solid #d5c196;
  box-shadow: 0 12px 24px 0 #eef6f9
}

.snipcart-form__birthdate.invalid select,
.snipcart-input--invalid {
  border: 1px solid #d93120
}

.snipcart-input--icon .snipcart-input__input {
  padding-left: 44px
}

.snipcart-input--togleable .snipcart-input__input {
  padding-right: 64px
}

.snipcart-form__birthdate.invalid select,
.snipcart-input__input {
  -webkit-box-flex: 1;
  flex: 1;
  outline: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px
}

.snipcart-form__birthdate.invalid select:-webkit-autofill,
.snipcart-input__input:-webkit-autofill {
  background-color: #e8f0fe !important
}

.snipcart-input__show-label {
  cursor: pointer;
  position: absolute;
  right: 12px;
  margin-bottom: 0;
  color: #989ba3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px
}

.snipcart-input__visibility-toggle {
  display: none
}

.snipcart-input__left-icon {
  position: absolute;
  left: 12px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center
}

.snipcart-input--no-style {
  border: none;
  background-color: transparent
}

.snipcart-input--no-style:focus-within {
  border: none;
  box-shadow: none
}

.snipcart-input--no-style .snipcart-input__input {
  padding: 0
}

.snipcart-form-radio {
  position: absolute;
  opacity: 0
}

.snipcart-form-radio__label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0;
  white-space: normal
}

.snipcart-form-radio + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  border-radius: 99px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  background: transparent;
  border: 1px solid #989ba3
}

.snipcart-form-radio:checked + label:before {
  background: -webkit-gradient(radial, left top, right top, from(#d5c196), to(#d5c196));
  background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 25%, rgba(213,193,150,1) 40%, rgba(213,193,150,1) 100%);
  border: none
}

.snipcart-form-radio:checked + label:after {
  content: "";
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: 99;
  position: absolute;
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border: none
}

.snipcart-typeahead__select {
  position: relative
}

.snipcart-typeahead__suggestions {
  position: relative;
  max-height: 100vh;
  height: 100vh;
  margin: 0;
  margin-top: -1px;
  padding: 0;
  list-style-type: none;
  z-index: 1;
  width: 100%;
  cursor: pointer;
  border: 1px solid #04befe;
  overflow-y: auto
}

@media (min-width: 1024px) {
  .snipcart-typeahead__suggestions {
    position: absolute;
    background-color: #fff;
    max-height: auto;
    height: auto;
    top: 100%;
    max-height: 300px;
    overflow-y: auto
  }
}

.snipcart-typeahead__suggestions--large {
  position: absolute;
  background-color: #fff;
  max-height: auto;
  height: auto;
  top: 100%;
  max-height: 300px;
  overflow-y: auto
}

.snipcart-typeahead__suggestion {
  margin: 0;
  padding: 8px 16px;
  color: #313332
}

.snipcart-typeahead__suggestion--active {
  background-color: #eee
}

.snipcart-typeahead__suggestion--secondary {
  color: #71a0aa
}

.snipcart-typeahead__suggestion--loading {
  color: #71a0aa;
  text-align: center
}

.snipcart-typeahead {
  background-color: #e7ebed
}

.snipcart-typeahead__input {
  cursor: pointer
}

.snipcart-typeahead__label {
  display: block;
  margin: 0;
  padding: 3.2px 0
}

.snipcart-typeahead__dropdown {
  background-color: #fdfefe
}

.snipcart-typeahead__dropdown--no-focus {
  cursor: pointer
}

.snipcart-typeahead--active {
  top: 0;
  left: 0;
  position: relative;
  height: 100%;
  background-color: transparent;
  z-index: 100;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain
}

.snipcart-typeahead--active, .snipcart-typeahead--active .snipcart-typeahead__content {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column
}

.snipcart-typeahead--active .snipcart-typeahead__content {
  background-color: #fff
}

.snipcart-typeahead--autofilled, .snipcart-typeahead--autofilled .snipcart-form__select {
  background-color: #e8f0fe
}

.snipcart-textbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-items: flex-start
}

.snipcart-textbox--hidden {
  border: none;
  background-color: transparent;
  width: 100%
}

.snipcart-textbox--hidden:focus {
  outline: none
}

.snipcart-textbox--with-icon {
  padding-left: 8px
}

.snipcart-textbox--focus {
  outline: none;
  border: 1px solid #d5c196;
  box-shadow: 0 12px 24px 0 #eef6f9
}

.snipcart-textbox__icon {
  margin-right: 10.66667px
}

.snipcart-layout {
  height: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column
}

.snipcart-layout__header {
  height: 100px;
  min-height: 100px;
  max-width: 1440px
}

@media (min-width: 768px) {
  .snipcart-layout__header {
    width: 80%;
    margin: 0 auto
  }
}

.snipcart-layout__header--tablet-up {
  width: 80%;
  margin: 0 auto
}

.snipcart-layout__content {
  height: 100%;
  max-width: 1440px
}

@media (min-width: 768px) {
  .snipcart-layout__content {
    // width: 80%;
    margin: 0 auto
  }
}

.snipcart-layout__content--tablet-up {
  width: 80%;
  margin: 0 auto
}

.snipcart-layout__content--side {
  min-height: 100%;
  width: 100%
}

.snipcart-layout__footer {
  background-color: #f0f5f6
}

.snipcart-layout__cols {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  justify-content: space-around
}

.snipcart-layout__col {
  width: 100%;
  margin: 0 auto
}

@media (min-width: 1024px) {
  .snipcart-layout__col {
    width: 50%;
    margin: 0 16px
  }
}

.snipcart-layout__col--large {
  //margin: 0 16px;
  width: 100%
}

@media (min-width: 1024px) {
  .snipcart-layout__col--large {
    //width: 70%
  }
}

.snipcart-layout__col--large--large {
  width: 70%
}

.snipcart-layout--large {
  left: 0;
  width: 100%
}

.snipcart-layout--no-scroll {
  overflow: hidden
}

@media (min-width: 1024px) {
  .snipcart-layout--no-scroll {
    overflow: auto
  }
}

.snipcart-layout--no-scroll--large {
  overflow: auto
}

.snipcart-order__payment-instructions {
  padding-top: 16px
}

.snipcart-card-icon {
  position: relative;
  margin-right: 8px;
  border: 1.25px solid #989ba3;
  border-radius: 3px;
  width: 32px;
  height: 22.85714px
}

.snipcart-card-icon svg {
  width: 100%;
  max-width: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.snipcart__loading__screen .snipcart__icon {
  width: 42px;
  height: 42px;
  -webkit-animation: spin .7s linear 0s infinite;
  animation: spin .7s linear 0s infinite
}

.snipcart__loading__screen__content {
  height: 50vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center
}

.snipcart__loading__screen__content__message {
  padding: 32px
}

.snipcart-loading-overlay {
  position: relative
}

.snipcart-loading-overlay__pending {
  position: absolute;
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 150
}

.snipcart-loading-overlay__pending .snipcart__icon {
  -webkit-animation: spin .7s linear 0s infinite;
  animation: spin .7s linear 0s infinite
}

.snipcart-loading-overlay__pending-message {
  padding: 32px
}

.snipcart-loading-overlay__content {
  z-index: 149
}

.snipcart-loading-overlay__content--loading {
  opacity: .5
}

.snipcart-loading-overlay__content--loading-opaque {
  opacity: 0
}

.snipcart-flash-message {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px
}

.snipcart-flash-message--error {
  background-color: rgba(217, 49, 32, .05)
}

.snipcart-flash-message--success {
  background-color: rgba(106, 227, 92, .15)
}

.snipcart-flash-message--info {
  background-color: rgba(82, 188, 248, .1)
}

.snipcart-flash-message:not(:first-of-type) {
  margin-top: 8px
}

.snipcart-flash-message__icon {
  display: none;
  min-width: 32px;
  height: auto;
  margin-bottom: auto;
  margin-right: 16px
}

@media (min-width: 768px) {
  .snipcart-flash-message__icon {
    display: block
  }
}

.snipcart-flash-message__icon--tablet-up {
  display: block
}

.snipcart-flash-message__action {
  margin-top: 8px
}

.snipcart-flash-message__content a {
  display: inline
}

.snipcart-flash-message__description:not(:first-child) {
  margin-top: 8px
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(110%);
    transform: translateX(110%)
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(110%);
    transform: translateX(110%)
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-webkit-keyframes slideDown {
  0% {
    height: 0
  }
  to {
    height: 100vh
  }
}

@keyframes slideDown {
  0% {
    height: 0
  }
  to {
    height: 100vh
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.snipcart-cart--opened {
  overflow: hidden
}

.snipcart-checkout-transitions-enter-active {
  -webkit-animation: slideIn .5s;
  animation: slideIn .5s
}

.snipcart-checkout-transitions-leave-active {
  animation: slideIn .3s reverse
}

.snipcart-featured-payment-methods {
  margin-bottom: 0;
}

.form-control.is-invalid {
  border: 1px solid #dc3545 !important;
}

$break-large: 1170px;
$break-tablet: 768px;
$break-mobile: 480px;

* {
  -webkit-overflow-scrolling: touch;
}

body {
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  //overflow: hidden;
  overflow-x: hidden;
  overflow-y: initial;
  background: #f7f8fb;
  opacity: 1;
  padding-top: env(safe-area-inset-top);
}

body.no-scroll {
  overflow: hidden !important;
}

body.scanner-active {
  background: transparent !important;
  --background: transparent !important;
  --ion-background-color: transparent !important;
  opacity: 0;
}

// Disable transitions on first run.
body.rui-no-transition * {
  // stylelint-disable-next-line
  transition: none !important;
}

.rui-icon.ion svg {
  color: #2b2b2b !important;
  fill: #2b2b2b !important;
}

svg.get-directions-icon {
  color: #d5c196 !important;
}

.btn-outline-primary:hover svg.get-directions-icon {
  color: #fefefe !important;
  transition: color 0.5s;
}

.btn-outline-success svg {
  color: #28a745 !important;
}

.plus-to-x svg {
  color: rgb(88, 96, 105) !important;
}

.fab.dark svg {
  color: #121212 !important;
}

// Simple page content
.rui-main {
  overflow: hidden;
}

// Main Content
.rui-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .rui-navbar-top ~ & {
    min-height: calc(100vh - 110px);
  }

  .rui-footer {
    margin-top: auto;
  }

  .rui-page-title {
    position: relative;
    display: block;
    padding: 30px 15px;

    @include media-breakpoint-down(xs) {
      padding: 30px 5px;
    }

    nav {
      + h1,
      + .h1 {
        margin-top: 5px;
      }
    }

    h1,
    .h1 {
      margin-bottom: -10px;
    }
  }

  .rui-page-content {
    position: relative;
    display: block;
    padding: 30px 15px;
    z-index: 1;

    @include media-breakpoint-down(xs) {
      padding: 30px 5px;
    }
  }

  // Sidebar
  .rui-page-sidebar-sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    will-change: top;
    transition: top .15s ease-in-out;

    &.rui-scrollbar .os-scrollbar {
      padding-right: 10px;

      &.os-scrollbar-vertical {
        width: 16px;
      }

      &.os-scrollbar-horizontal {
        height: 8px;
      }
    }

    .rui-page-sidebar {
      padding-left: 15px;
      margin-left: -15px;
    }
  }

  .rui-page-sidebar {
    height: auto;
    max-height: 100vh;
    border-left: 1px solid $color_separator;

    > .nav {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 15px;
      margin: 0;
      font-size: 13px;
      background-color: #fff;
    }
  }

  // React App.
  &.rui-page-react {
    min-height: auto;

    > div {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    .rui-navbar-top ~ & {
      min-height: auto;

      > div {
        min-height: calc(100vh - 110px);
      }
    }
  }
}

.search-results-container {
  .MuiListItem-root.Mui-selected {
    background-color: #c2b59b;
    border-radius: 5px;
  }
}

// Tablet only styles
@media screen and (hover: none) and (pointer: coarse) and (min-width: $break-tablet) {
  .rui-page {
    margin-left: 0;//60px !important;
    margin-top: 40px;
  }
  .yaybar.yay-hide-to-small {
    display: none !important;
  }
  //.search-results-container {
  //  position: absolute !important;
  //}
}
// Remove some paddings on mobile.
@include media-breakpoint-down(sm) {
  body:not(.rui-section-lines) {
    &.yay-hide .yaybar.yay-hide-to-small .yaybar-wrap > ul {
      padding-top: 0;
    }

    .yaybar.yay-hide-to-small ~ .rui-navbar-mobile {
      left: 0;

      .rui-navbar-head {
        transition: padding-left .15s ease-in-out;
      }

      &:not(.rui-navbar-show) .rui-navbar-head {
        padding-left: 0;
      }
    }

    .yaybar.yay-hide-to-small ~ .content-wrap {
      .rui-page-title {
        padding-top: 5px;
        padding-left: 0;

        > .container,
        > .container-fluid {
          padding-left: 0;
        }
      }

      .rui-page-content {
        padding-left: 0;

        > .container,
        > .container-fluid {
          padding-left: 0;
        }
      }

      .rui-footer,
      .rui-footer .container,
      .rui-footer .container-fluid {
        padding-left: 0;
      }
    }
  }
}

// Autohide
.rui-navbar-autohide {
  .rui-page-sidebar-sticky {
    margin-top: -30px;
    transform: translateY(0);
  }

  &.rui-navbar-show .rui-page-sidebar-sticky {
    top: 70px;
  }
}

// Separetor
.rui-separator {
  display: block;
  border-bottom: 1px solid $color_separator;
}


// Object Fit background
.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    // stylelint-disable-next-line
    font-family: "object-fit: cover";
    object-fit: cover;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: 1;
  }
}

// gaps
.rui-gap,
.rui-gap-1,
.rui-gap-2,
.rui-gap-3,
.rui-gap-4,
.rui-gap-5,
.rui-gap-6 {
  display: block;
  height: 15px;
}

.rui-gap-1 {
  height: 25px;
}

.rui-gap-2 {
  height: 30px;
}

.rui-gap-3 {
  height: 40px;
}

.rui-gap-4 {
  height: 60px;
}

.rui-gap-5 {
  height: 80px;
}

.rui-gap-6 {
  height: 100px;
}

// body scrollbar checker
.rui-body-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Gmap
.rui-gmap {
  min-height: 72vh;
  border-radius: .25rem;
}

.card a {
  color: #2b2b2b;
}

// FIXME Tried doing this to fix card background issue on desktop detail pages
//.card, .item,
//.related-item,
//.top-rated-item,
//.makeStyles-vineMenu-360,
//.search-results-container,
//.feed-session-card-header,
//.card-item .content,
//.card-item > .image > div,
//.feed-card-item .content,
//.feed-card-item > .image > div,
//.explorer-card-item .content,
//.explorer-card-item > .image > div,
//.humidor-card-item,
//.session-notes-card {
//    background-color: #ffffff !important;
//}

.feed-session-card-header {
  background-color: #ffffff;
}

.horizontal-chips-wrapper.vitola-chips {
  background-color: #f7f8fb;
}

.card-header-badge {
  background-color: rgb(229, 229, 229);
}

.menu-icons {
  background-color: rgba(0, 0, 0, 0.54);
}

.menu-list-heading {
  color: rgba(0, 0, 0, 0.54);
}

.profile-list,
.profile-list-item {
  background-color: #ffffff;
  border-bottom: 1px solid #dfdfdf;
}

.rui-navbar-collapse {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px;
}

#image-picker-options .MuiListItemIcon-root,
#video-picker-options .MuiListItemIcon-root {
  min-width: 36px !important;
}

.MuiListItem-secondaryAction {
  padding-right: 60px !important;
}

@media screen and (max-width: 320px) {
  .MuiToggleButtonGroup-grouped {
    padding: 0 12px !important;
  }
}

.deactivate-account-wrapper {
  background-color: #ffffff;
  padding: 10px;
}

.filter-action-buttons {
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: #f7f8fb;
  margin-left: -20px;
  margin-right: -20px;
  //width: 300px !important;
  padding: 10px 20px;
  border-top: 1px solid #e1e1e1;
}

@media screen and (max-width: 1024px) {
  .filter-action-buttons {
    background-color: #ffffff;
  }
}

.action-button-wrapper {
  position: sticky;
  top: 112px;
  z-index: 1002;
  background-color: #f7f8fb;
}

.action-button-outline {
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px;
  min-width: calc(50% - 5px);
  color: #69798F;
}

.divider {
  margin-left: 20px;
  margin-right: 20px;
  height: 1px;
  border-bottom: 1px solid #efefef;
}

.bottom-divider {
  border-bottom: 1px solid #efefef;
}

//.webkit-sassui-ios-modal > .ft button {
//    color: #d5c196 !important;
//}

.padded-multiline {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

button:focus {
  outline: none !important;
}

.humidor-card-item {
  background-color: #ffffff;
  box-shadow: 0 2px 12px 1px rgba(173, 173, 173, 0.2);
}

.feed-card-item {
  position: relative;
  margin: 16px auto 4px;
  width: 100%;
  max-width: 580px;
  box-shadow: 0 2px 12px 1px rgba(173, 173, 173, 0.2);

  .image {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    border-radius: 3px;
    box-shadow: none;
    bottom: auto;
    position: relative;
    background-color: #ffffff;

    .video {
      width: 100% !important;
      height: 342px; // TODO This should get set using JS depending on the video ratio
    }

    .img {
      width: calc(100% - 20px);
      //height: 233px;
      padding-top: 80%;
      background-size: cover;
      background-position: center center;
      box-shadow: none;
      margin: 10px;
      border-radius: 5px;
    }

    .video, .video-contain {
      width: 100% !important;
      video {
        width: 100%;
      }
    }
  }

  .content {
    background-color: #FFFFFF;
    border-radius: 3px;
    box-shadow: none;
    float: none;
    position: relative;
    min-height: auto;
    margin-top: 0;
    margin-left: 0;
    width: 100%;

    &.margin {
      margin-top: 3%;
    }

    .margin-left {
      margin-left: 300px;
    }

    .icon {
      float: right;
      margin-right: 0;

      .bookmark, .heart, .comment {
        float: right;
        font-size: 12px;
        color: #bbb;
      }

      .bookmark, .comment {
        margin-left: 10px;
      }
    }

    .author {
      bottom: 10px;
      font-size: 1em;
      clear: both;
      float: left;
      left: 0px;
      position: relative;
      padding: 20px 20px 0px;
      width: 100%;

      a {
        opacity: 0.7;
      }
    }
  }

  .content-right {
    width: 100%;
    float: none;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    min-height: auto !important;

    a {
      color: #4b515b;
    }
  }

  h3 {
    font-size: 1.3em;
    margin: 16px 0 10px;
    @media screen and (max-width: $break-mobile) {
      //padding: 0 24px;
    }
  }

  .intro {
    font-size: 1em;
    margin: 20px 0;
    text-align: justify;
    //padding-right: 26px;
    width: 100%;

    a {
      line-break: anywhere;
    }

    h3 {
      font-size: 1em;
    }
  }
}

.content-bottom {
  position: relative;
  left: 20px;
  width: calc(100% - 20px);

  .shop-logo {
    margin-right: 20px;

    width: auto;
    max-height: 50px;
    display: block;
  }
}

.cover-text.visible.slide.animate-text {
  display: none !important;
}

.slick-track {
  min-width: 100%;
}

.select-error,
.select-error > div {
  border-color: $danger !important;
}

.MuiChip-icon {
  margin-left: 10px !important;
  margin-right: -10px !important;
}

.MuiAvatar-colorDefault {
  color: #aaa;
  background-color: #efefef;
}

.stepper-wizard-header {
  background-color: #f7f8fb;
}

.gm-style {
  .gm-style-iw-c {
    .gm-style-iw-d {
      overflow: hidden !important;
      padding: 12px;
    }
  }
}

.current-location-btn {
  height: 36px;
  background-color: #fbfcfc;
  color: #6c757d;
  border: 1px solid #cccccc !important;
}

.card .feed-card-item {
  box-shadow: none;
}

.feed-card-item.card-padded-bottom {
  border-bottom: 12px solid #f7f8fb;
}

.feed-card,
.feed-card-item {
  a {
    color: #4b515b;
  }
  .rich-content {
    a {
      color: #121212;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.humidor-list-item.active {
  background-color: #e5e5e5;
}

.avatar-contained img {
  object-fit: contain !important;
}

.simple-carousel {
  display: flex;
  -webkit-overflow-scrolling: touch;
  //width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
}

.cigar-recommendation-card,
.shop-all-product-card {
  width: 145px !important;
  min-width: 145px !important;
  padding-left: 4px;
  padding-right: 4px;
}

.MuiChip-clickableColorPrimary:hover,
.MuiChip-clickableColorPrimary:focus {
  background-color: rgb(196, 178, 132) !important;
  color: #2b2b2b !important;
}

.react-autosuggest__input {
  color: #24262a;
}

#quick-action-chips {
  background-color: #f7f8fb;
  height: 56px;
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  top: calc(110px + env(safe-area-inset-top));
  position: absolute;
  z-index: 1003;
  //overflow-scrolling: touch !important;
  //-webkit-overflow-scrolling: touch !important;
  //-webkit-transform: translate3d(0,0,0);
}

.Select-option.is-focused {
  background-color: rgb(196, 178, 132) !important;
  color: #2b2b2b !important;
}

.google-places-autocomplete__suggestion {
  padding: 16px 8px 4px;
}

.bg-facebook {
  background-color: #335397;
  text-transform: uppercase;
  color: #ffffff;
}

.section-header {
  background: #cccccc;
  padding: 4px 12px;
}

#main-mobile-search-bar {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2);
  margin-bottom: 16px;
  position: sticky;
  top: calc(56px + env(safe-area-inset-top));
  z-index: 1000;
}

.rui-navbar-ios .makeStyles-search-54,
.rui-navbar-ios #mobile-search-input {
  height: 14px;
}

.MuiButton-root,
#mobile-search-input::placeholder {
  color: #24262a !important;
}

.error-placeholder::placeholder {
  color: #ef5164 !important;
}

.form-control.error-placeholder {
  border: 1px solid #ef5164;
}

/*
 "ResizeObserver loop limit exceeded" fix
 https://bugs.chromium.org/p/chromium/issues/detail?id=809574
 */
video::-webkit-media-controls-timeline {
  align-self: center;
  width: calc(100% - 64px);
}

.card {
  box-shadow: 0 2px 12px 1px rgba(173, 173, 173, 0.2);
}

input.no-background {
  border: none;
  background: transparent;
  padding: 0;
  line-height: 30px;
  width: 100%;
  font-size: 1rem;
}

.cigar-skeleton-card,
.top-rated-card {
  background-color: #ffffff;
  box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}

.rui-messenger-message img {
  max-width: 100%;
}

.sms-prompt-input .MuiInputBase-input {
  color: #2b2b2b !important;
  font-size: 1.2rem;
}

span.onesignal-checkmark,
button#onesignal-slidedown-allow-button {
  background-color: rgb(196, 178, 132) !important;
}

#slidedown-footer > .align-right.secondary.slidedown-button {
  color: rgb(196, 178, 132) !important;
}

.btn.btn-outline-secondary:focus {
  color: #656565 !important;
}

.btn.btn-coinbase {
  background-color: #0062c6;
  border: medium none;
  margin: 12px auto;
  color: white;
  border-radius: 4px;
  width: 100%;
  max-width: 570px;
  height: 55px;
  text-transform: none;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
}

.venue-card-availability {
  .MuiFormControlLabel-root {
    margin-right: 8px !important;
  }
}

.MuiListItem-root {
  cursor: pointer;
}

@media screen and (orientation : landscape) and (min-width: 1024px) {
  .rui-page {
    margin-left: 260px;
  }
}

@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  .rui-page {
    margin-left: 0;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
  .rui-page {
    margin-left: 0;
  }
}

.btn-primary {
  color: #121212;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus, .btn-primary.focus,
.btn-primary:hover {
  background-color: #c2b59b;
  border-color: #c2b59b;
  color: #121212;
  box-shadow: none;
}

svg.social {
  margin: 0 10px;
  height: 24px !important;
  width: 24px !important;
}

svg.social.outline {
  & > path {
    fill: rgba(0,0,0,0.3);
    //fill: transparent;
    //stroke: currentColor;
    //stroke-width: 30px;
  }
}

svg.social.filled {
  & > path {
    fill: currentColor;
    //stroke: none;
    //stroke-width: 0;
  }
}

svg.social.filled.facebook {
  & > path {
    fill: #4267B2;
  }
}

svg.social.filled.twitter {
  & > path {
    fill: #1DA1F2;
  }
}

svg.social.filled.foursquare {
  & > path {
    fill: #FA4779;
  }
}

svg.social.filled.swarm {
  & > path {
    fill: #ffa633;
  }
}

svg.social.filled.untappd {
  & > path {
    fill: #ffc000;
  }
}

.scanner-ui { display: none; }
.scanner-hide { visibility: visible; }

.qrscanner {
  background-color: transparent;
  #app {
    display: none;
  }
}
.qrscanner .scanner-ui { display: block; }
.qrscanner .scanner-hide { visibility: hidden; }

.qrscanner .boxpressd-modal-view {
  display: none !important;
}
.barcode-scanner-modal {
  .MuiDrawer-paper {
    background: transparent !important;
  }
}

.lounge-messenger-message a {
  color: #00507f;
}

.image-wrapper {
  background-color: #ffffff;
}

.link {
  cursor: pointer;
}

.fancybox-thumbs__list a::before {
  border: 6px solid rgb(196, 178, 132) !important;
}

.fancybox-thumbs {
  background: transparent !important;
}

.fancybox-container {
  margin-top: env(safe-area-inset-top);
  height: calc(100% - env(safe-area-inset-top));
}

.edit-btn-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 26px;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  color: #ffffff;
}

.edit-cover-photo-btn-overlay.show {
  display: block;
}
.edit-cover-photo-btn-overlay {
  display: none;
  position: absolute;
  top: 94px;
  right: 20px;
  width: 140px;
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  background-color: rgba(256, 256, 256, 0.8);
  text-align: center;
  color: #2b2b2b;
  cursor: pointer;
}

@media (min-width: 1370px) {
  .edit-cover-photo-btn-overlay {
    right: calc(50% - 640px);
  }
}

@media screen and (max-width: 766px) {
  .edit-cover-photo-btn-overlay {
    top: 124px;
  }
}

.MuiBadge-colorSuccess {
  color: #ffffff !important;
}

.boxpressd-comments {
  //max-width: 800px;
  margin: auto;
}

.rating-star-container {
  span {
    font-size: 10pt !important;
  }
}

.wp-block-image {
  img {
    max-width: 100%;
  }
}

.transparent-background {
  background-color: transparent !important;
}

.contenteditable.form-control {
  background: #efefef;
  cursor: text;
  height: auto;
}

[contenteditable=true]:empty:before {
  color: #656565;
  content: attr(placeholder);
  display: block;
}

.content_body_text p {
  margin-bottom: 28px;
  font-size: 16px;
}

.pac-container {
  z-index: 10000 !important;
}

.content_body_text a {
  text-decoration: underline;
}

#react-autowhatever-mobile-search-input svg {
  color: #121212;
}

#brand-name svg,
#venue-name svg {
  color: rgb(196, 178, 132) !important;
}

iframe#tidio-chat-iframe {
  max-height: calc(100vh - env(safe-area-inset-top)) !important;
}

.material-snackbar {
  background-color: #c2b59b !important;
  .MuiIconButton-colorInherit,
  .MuiSnackbarContent-message {
    color: #17191d !important;
  }
}

.MuiButton-containedPrimary .MuiButton-label {
  color: #17191d !important;
}

.react-actionsheet {
  position: absolute;
  z-index: 10000;
}

.rich-content .emoji {
  height: 20px;
}

@media screen and (max-width: 676px){
  #chat-button {
    display: none !important;
  }
}

.jss8,
.jss8:hover,
.jss25,
.jss25:hover,
[class*="makeStyles-search-"] {
  background-color: #fff !important;
  border: none;
  margin-top: 0 !important;
}

.MuiList-root,
.MuiPaper-root,
.MuiAppBar-root,
.rui-navbar {
  background-color: #4a3025 !important;
}

.MuiAppBar-root {
  color: #efefef !important;
}

.MuiDrawer-paper {
  background-color: #fff !important;
  .MuiList-root {
    background-color: #fff !important;
  }
}

.rui-navbar {
  svg {
    color: #ffffff !important;
  }
}

.MuiList-padding {
  background-color: #fff !important;
}

.MuiChip-root {
  color: #2b2b2b;
  //background-color: #c2b59b !important;
  border-radius: 5px !important;
}

.MuiLinearProgress-barColorPrimary,
.star-color {
  background-color: #ff9e2d !important;
}

.MuiAppBar-root,
.MuiMobileStepper-root {
  .MuiButton-label {
    color: #ffffff;
  }
}

#humidor-filter {
  .jss8, [class*="makeStyles-search-"] {
    margin-top: 4px !important;
  }
}

[class*="makeStyles-search-"] {
  .MuiButton-label {
    color: #2b2b2b;
  }
}

[class*="makeStyles-searchIcon-"] {
  svg {
    color: #2b2b2b !important;
  }
  background-color: transparent !important;
}

[class*="makeStyles-inputRoot-"] {
  background-color: transparent !important;
}

.MuiAlert-root {
  background-color: transparent !important;
}

.rui-navbar-avatar {
  span {
    color: #ffffff !important;
  }
}

.MuiPopover-paper {
  background-color: #ffffff !important;
}

.boxpressd-comments {
  .lounge-messenger-message:not(.lounge-messenger-message-incoming) {
    margin-left: 4px;
  }

  .lounge-messenger-message {
    overflow: hidden;
    word-break: break-word;
  }

  .lounge-messenger-message > ul {
    width: 100%;
    background-color: #dfdfdf;
    border-radius: 0 6px 6px 6px !important;
    color: #2b2b2b;
  }

  .lounge-messenger-message-time {
    right: 0 !important;
    left: auto !important;
  }
}

.lounge-messenger-message {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column-reverse;
  margin: 16px 16px 40px;

  video, img {
    max-width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > ul {
    display: inline-block;
    order: 1;
    padding: 0;
    margin: 0;
    color: #fff;
    //text-align: right;
    background-color: rgba($color_brand, .8);
    border-radius: 6px 6px 0;

    > li {
      position: relative;
      padding: 10px 15px;
      list-style: none;

      + li {
        padding-top: 0;
      }
    }
  }

  // Time
  .lounge-messenger-message-time {
    top: 100%;
    right: 0;
    margin-top: 5px;
    color: $color_grey_4;
  }

  // Edit btns
  &:not(.lounge-messenger-message-incoming) {
    > ul {
      order: 1;
    }

    > .lounge-messenger-message-edit {
      order: 0;
      margin-right: 7px;
      margin-bottom: 3px;
    }
  }

  .lounge-messenger-message-edit {
    display: flex;
    opacity: 0;
    will-change: opacity;
    transition: opacity .15s ease-in-out;

    > .btn {
      padding: 10px 8px;
      color: $color_grey_4;
      border: 0;

      &:hover,
      &.hover {
        color: $color_text_1;
      }

      &:focus,
      &.focus {
        box-shadow: none;
      }
    }
  }

  // Structure (Block)
  &.lounge-messenger-message-structure {
    display: none;

    + .lounge-messenger-datetime {
      padding-top: 0;
      margin-top: -4px;
    }
  }

  // Incoming
  &.lounge-messenger-message-incoming {
    justify-content: flex-start;
    align-items: flex-start;

    .lounge-messenger-message-time {
      right: auto;
      left: 0;
    }

    > ul {
      color: $color_text_2;
      text-align: left;
      background-color: #dfdfdf;
      border-radius: 6px 6px 6px 0;
    }
  }

  &:hover,
  &.hover {
    .lounge-messenger-message-edit {
      opacity: 1;
    }
  }
}

#snipcart {
  //display: none !important;
  opacity: 0 !important;
  height: 0 !important;
}

html.snipcart-cart--opened, html.snipcart-cart--opened body {
  height: auto !important;
  overflow: scroll !important;
}

.snipcart-form {
  box-shadow: 0 2px 12px 1px rgba(173, 173, 173, 0.2);
}

.snipcart-cart-header {
  display: none;
}

.snipcart-modal {
  margin-top: 75px;
}

.snipcart-form__birthdate select,
.snipcart-input__input {
  height: 52px;
}

.snipcart-inline-discount-btn {
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.snipcart-address-list-item,
.snipcart-shipping-rates-list-item {
  cursor: pointer;
  display: grid;
  grid-template-columns: 80% 20%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin: 3.2px 0;
  padding: 16px;
  background-color: #fdfefe;
  border: 1px solid #cccccc;
  border-radius: 4px;
  svg {
    min-width: 24px;
    height: 24px;
  }
}

.snipcart-cart-button--highlight {
  background-image: linear-gradient(#28a745, #28a745) !important;
  border-radius: 5px;
  margin-bottom: 30px;
}

.snipcart-cart-button--highlight.disabled {
  background-image: linear-gradient(#565656, #565656) !important;
}

.snipcart__box--badge-highlight {
  background: -webkit-gradient(linear,left top,right top,from(#d5c196),to(#d5c196));
  background: linear-gradient(90deg,#d5c196,#d5c196);
  color: #fff;
}

.snipcart-layout__header {
  opacity: 0;
}

.snipcart-modal__container {
  z-index: 1000;
}

.snipcart-modal__container,
.snipcart-modal,
.snipcart-cart__content,
.snipcart-featured-payment-methods__title,
.snipcart-discount-box__button {
  background-color: #f7f8fb;
}

.snipcart-item-line__title {
  flex: auto;
}

// FIXME probably tablets too
@media (min-width: 1024px) {
  .snipcart-modal__container {
    padding-left: 230px;
  }
}

.boxpressd-cart__line-items-header,
.boxpressd-cart__line-items-footer {
  background-color: #ffffff;
  padding: 16px;
}

.boxpressd-cart__line-items-header {
  border-bottom: 1px solid #f0f5f6;
  h3 {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.boxpressd-cart__line-items-footer {
  border-top: 1px solid #f0f5f6;
  text-align: center;
}

.snipcart-item-quantity__quantity {
  background-color: rgba(0, 0, 0, 0.1);
}

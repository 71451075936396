@viewport { viewport-fit: cover; }

@import "../common-assets/css/variables";

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
@import "../common-assets/css/bootstrap-custom";

/*------------------------------------------------------------------

  Base

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/base";

/*------------------------------------------------------------------

  Shop

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/shop";

/*------------------------------------------------------------------

  Cart

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/cart";

/*------------------------------------------------------------------

  Preloader

 -------------------------------------------------------------------*/
// @import "../common-assets/css/rootui-parts/preloader";

/*------------------------------------------------------------------

  Typography

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/typography";

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap";

/*------------------------------------------------------------------

  Helpers

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/helpers";

// Layouts

/*------------------------------------------------------------------

  Navbar

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/navbar";

/*------------------------------------------------------------------

  Spotlight Mode

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/spotlight-mode";

/*------------------------------------------------------------------

  Section lines

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/section-lines";

/*------------------------------------------------------------------

  Header

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/header";

/*------------------------------------------------------------------

  Sign

 -------------------------------------------------------------------*/
// @import "../common-assets/css/rootui-parts/sign";

/*------------------------------------------------------------------

  Error

 -------------------------------------------------------------------*/
// @import "../common-assets/css/rootui-parts/error";

/*------------------------------------------------------------------

  Mailbox

 -------------------------------------------------------------------*/
// @import "../common-assets/css/rootui-parts/mailbox";

/*------------------------------------------------------------------

  Messenger

 -------------------------------------------------------------------*/
// @import "../common-assets/css/rootui-parts/messenger";

/*------------------------------------------------------------------

  Virtual Lounge

 -------------------------------------------------------------------*/
 @import "../common-assets/css/rootui-parts/lounge";

/*------------------------------------------------------------------

  Project Management

 -------------------------------------------------------------------*/
// @import "../common-assets/css/rootui-parts/project-management";

/*------------------------------------------------------------------

  File Manager

 -------------------------------------------------------------------*/
// @import "../common-assets/css/rootui-parts/file-manager";

/*------------------------------------------------------------------

  Profile

 -------------------------------------------------------------------*/
// @import "../common-assets/css/rootui-parts/profile";

/*------------------------------------------------------------------

  Footer

 -------------------------------------------------------------------*/
@import "../common-assets/css/rootui-parts/footer";


/*------------------------------------------------------------------

  Elements

 -------------------------------------------------------------------*/

/*---------------------------
  Element Alert
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-alert";

/*---------------------------
  Element Badge
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-badge";

/*---------------------------
  Element Breadcrumb
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-breadcrumb";

/*---------------------------
  Element Buttons
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-buttons";

/*---------------------------
  Element Card
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-card";

/*---------------------------
  Element Carousel
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-carousel";

/*---------------------------
  Element Collapse
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-collapse";

/*---------------------------
  Element Table
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-table";

/*---------------------------
  Element Pagination
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-pagination";

/*---------------------------
  Element Dropdown
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-dropdown";

/*---------------------------
  Element Forms
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-forms";

/*---------------------------
  Element List group
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-list-group";

/*---------------------------
  Element Media object
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-media-object";

/*---------------------------
  Element Modal
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-modal";

/*---------------------------
  Element Navs
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-navs";

/*---------------------------
  Element Popovers
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-popovers";

/*---------------------------
  Element Progress
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-progress";

/*---------------------------
  Element Spinners
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-spinners";

/*---------------------------
  Element Toast
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/bootstrap-toast";

/*---------------------------
  Element Blockquote
 ----------------------------*/
@import "../common-assets/css/rootui-parts/bootstrap-blockquote";

/*---------------------------
  Element Search
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/element-search";

/*---------------------------
  Element Popup
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/element-popup";

/*---------------------------
  Element Snippet
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/element-snippet";

/*---------------------------
  Element Timeline
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/element-timeline";

/*---------------------------
  Element Changelog
 ----------------------------*/
@import "../common-assets/css/rootui-parts/element-changelog";

/*---------------------------
  Element Kanban
 ----------------------------*/
@import "../common-assets/css/rootui-parts/element-kanban";

/*---------------------------
  Element Task
 ----------------------------*/
@import "../common-assets/css/rootui-parts/element-task";

/*---------------------------
  Element Icons
 ----------------------------*/
@import "../common-assets/css/rootui-parts/element-icons";

/*---------------------------
  Element Content Boxes
 ----------------------------*/
@import "../common-assets/css/rootui-parts/element-content-boxes";

/*---------------------------
  Element Image Boxes
 ----------------------------*/
@import "../common-assets/css/rootui-parts/element-image-boxes";

/*---------------------------
  Element Widget
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/element-widget";

/*---------------------------
  Element Social links
 ----------------------------*/
@import "../common-assets/css/rootui-parts/element-social-links";

/*---------------------------
  Element Box Window
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/element-box-window";

/*---------------------------
  Element Feature
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/element-feature";

/*------------------------------------------------------------------

  Plugins

 -------------------------------------------------------------------*/

/*---------------------------
    Plugin Overlay Scrollbars
 ----------------------------*/
@import "../common-assets/css/rootui-parts/plugin-overlay-scrollbars";

/*---------------------------
    Plugin Emojione Area
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-emojione-area";

/*---------------------------
    Plugin Sortablejs
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-sortable";

/*---------------------------
    Plugin Swiper
 ----------------------------*/
@import "../common-assets/css/rootui-parts/plugin-swiper";

/*---------------------------
    Plugin Fullcalendar
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-fullcalendar";

/*---------------------------
    Plugin Fancybox
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-fancybox";

/*---------------------------
    Plugin Jstree
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-jstree";

/*---------------------------
    Plugin Sweetalert
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-sweetalert";

/*---------------------------
    Plugin Jqvmap
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-jqvmap";

/*---------------------------
    Plugin Datatable
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-datatable";

/*---------------------------
    Plugin DateTime Picker
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-datetime";

/*---------------------------
    Plugin DateRange Picker
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-daterange";

/*---------------------------
    Plugin Touchspin
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-touchspin";

/*---------------------------
    Plugin Ion Range Slider
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-irs";

/*---------------------------
    EasyMDE (Markdown)
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-easymde";

/*---------------------------
    Colorpicker
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-pickr";

/*---------------------------
    Plugin Chartist
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-chart-chartist";

/*---------------------------
    Plugin Selectize
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-selectize";

/*---------------------------
    Plugin Peity (chart)
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-chart-peity";

/*---------------------------
    Plugin eCharts
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-chart-echarts";

/*---------------------------
    Plugin Flot (chart)
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-chart-flot";

/*---------------------------
    Plugin Chartjs
 ----------------------------*/
@import "../common-assets/css/rootui-parts/plugin-chartjs";

/*---------------------------
    Plugin Highlight
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-highlight";

/*---------------------------
    Plugin Quill
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-quill";

/*---------------------------
    Plugin Dropzone
 ----------------------------*/
// @import "../common-assets/css/rootui-parts/plugin-dropzone";

// FIXME Add this to its own file and import like above
@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/slick-carousel/slick/slick-theme.css';
